import React, { Suspense, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { HashRouter as Router } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { me } from 'graphql/queries';
import { User } from 'types/types.d';
import { projectViewLayoutSet, userLoaded } from 'slices/authSlice';
import ModalProvider from 'contextProviders/ModalProvider';
import TooltipProvider from 'contextProviders/TooltipProvider';
import logger from 'utils/logger/logger';
import { URL_QUERY_PARAM_LANGUAGE } from 'utils/routingUtils';
import { getSearchParamsFromUrl } from 'utils/windowUtils';
import { Loading, Modal, Tooltip } from 'components/common';
import AppBody from 'components/appBody';
import styles from './app.module.scss';
import WhitelabelProvider from 'contextProviders/WhitelabelProvider';
import { ConfigProvider, theme } from 'antd';
import { black_0, black_500, black_70, black_700, main_black_900, primary } from 'utils/styleUtils';

/**
 * - Checks currently logged-in user by sending a GraphQL request.
 * - URL-provided language is checked and then user-preferred one is checked and set if present.
 *
 * Note: 1) the user's role and 2) whether a user is logged in define which routes are allowed to be accessed
 */
const App = (): JSX.Element => {
  const dispatch = useDispatch();

  const { loading, error, data } = useQuery<{ me?: User }>(me);

  const searchParams = getSearchParamsFromUrl(location.href);
  let language = searchParams.get(URL_QUERY_PARAM_LANGUAGE); // login?lang=en

  if (loading) return <></>;

  let user: User | undefined = undefined;
  if (error) {
    logger.error(`error loading logged in user: ${error.message}`);
  } else {
    user = data?.me;
    if (user) {
      language = user.settings.languageIso6391;
      dispatch(userLoaded(user));
      if (user?.settings?.projectViewLayout) dispatch(projectViewLayoutSet(user.settings.projectViewLayout));
    }
  }

  return <AppComponent language={language} />;
};

interface AppComponentProps {
  language: string | undefined;
}

/**
 * The main reason to separate this component from the upper one is to separate the hooks to fetch user and language.
 * Otherwise user is fetched every time the language changes and this is creates a cyclic dependency.
 */
const AppComponent = ({ language }: AppComponentProps) => {
  const { i18n } = useTranslation();

  // check and change language (on the initial load only) if it's not a default one
  useEffect(() => {
    if (language && language !== i18n.language) {
      i18n.changeLanguage(language);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [language]);

  // moment.locale('en-gb');
  return (
    <ConfigProvider
      theme={{
        algorithm: theme.darkAlgorithm,
        token: {
          colorText: black_0,
          // colorBorder: black_0,
          colorPrimary: primary,
          colorPrimaryHover: black_0,
          colorBgBase: black_700,
          colorBorder: black_0,
          colorPrimaryBorderHover: black_0,
        },
        components: {
          Modal: {
            headerBg: black_700,
            contentBg: black_700,
            footerBg: black_700,
          },
          Select: {
            optionSelectedBg: black_500,
            optionLineHeight: '2.25rem',
            colorBgContainer: black_700,
            colorBorder: black_0,
          },
          Checkbox: {
            colorPrimary: main_black_900,
            colorBorder: black_0,
            colorBgContainer: black_500,
            colorPrimaryHover: black_500,
          },
          Table: {
            colorBgContainer: black_70,
            colorTextBase: black_0,
            rowHoverBg: black_500,
            rowSelectedBg: black_500,
            rowSelectedHoverBg: black_500,
          },
          Pagination: {
            colorPrimary: black_0,
            colorText: black_0,
            colorBgContainer: black_700,
          },
          Segmented: {
            itemColor: black_0,
            itemSelectedBg: primary,
            itemSelectedColor: main_black_900,
          },
        },
      }}
    >
      <Router>
        <Suspense
          fallback={
            <div className={styles.suspense}>
              <Loading />
            </div>
          }
        >
          <TooltipProvider>
            <ModalProvider>
              <WhitelabelProvider>
                <AppBody language={i18n.language} />
                <Modal />
                <Tooltip />
              </WhitelabelProvider>
            </ModalProvider>
          </TooltipProvider>
        </Suspense>
      </Router>
    </ConfigProvider>
  );
};

export default App;
