import React, { createContext, useCallback, useEffect, useMemo, useState } from 'react';
import { useLazyQuery } from '@apollo/client';
import { PRODUCTION, TEST } from 'utils/routingUtils';
import useCustomLocation, { SearchParams } from 'hooks/useCustomLocation';
import { getObjectUrlParams } from 'utils/stringUtils';
import { AirtablePaging, AirtableRecord, AirtableSort } from 'types/businessTypes.d';
import { getAirtableRecords } from 'graphql/businessQueries';
import { TFunction, useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Client, Clients, SecurityRole } from 'types/types.d';
import { getClients } from 'graphql/queries';
import { useTrackedSelector } from 'components/app/store';
import { OrderBy, SearchPaging, Sorting } from 'types/inputTypes';
import { userHasAnyRole } from 'utils/userUtils';

export const TestContext = createContext<TestContextProps | null>(null);

type SortDirection = 'asc' | 'desc';

export enum TabId {
  AdConcept = 'ad-concept',
  AbTest = 'ab-test',
  Performance = 'performance',
  Roadmap = 'roadmap',
  Backlog = 'backlog',
}

type AirtableField = {
  first: string;
  second?: string;
};

export const sortedFieldOrder: Record<TabId, string[]> = {
  [TabId.AdConcept]: [
    'AI-ID',
    'ACID',
    'AC name',
    'AC description',
    'North Star Metric',
    'Cost per North Star Metric',
    'Observations',
    'Learnings',
    'Next Steps',
  ],
  [TabId.AbTest]: [
    'Test ID',
    'Variable',
    'Test name',
    'PGID (A)',
    'PGID (B)',
    'Description (A)',
    'Description (B)',
    'North Star Metric (A)',
    'Cost per North Star Metric (A)',
    'North Star Metric (B)',
    'Cost per North Star Metric (B)',
    'Observations',
    'Learnings',
    'Next Steps',
    'Impact',
  ],
  [TabId.Performance]: [
    'Test ID',
    'Campaign name',
    'Description of variable',
    'Meta URL',
    'Goal of the test',
    'Observation',
    'Analysis & Evaluation',
    'Winner / Loser',
    'Next steps',
    'Impact',
  ],
  [TabId.Roadmap]: ['AC Name', 'Type', 'USP', 'Asana', 'Estimated Ship date'],
  [TabId.Backlog]: ['Test Idea', 'Description', 'Impact', 'Confidence', 'Ease'],
};

export const tabs = (t: TFunction) => {
  return [
    {
      id: TabId.AdConcept,
      title: t('PRIVATE.USER.TEST.AD_CONCEPT'),
      tableId: 'tblHhkRKmrRNkAwiQ',
    },
    {
      id: TabId.AbTest,
      title: t('PRIVATE.USER.TEST.AB_TEST'),
      tableId: 'tblN5EqKcZubYRPm8',
    },
    {
      id: TabId.Performance,
      title: t('PRIVATE.USER.TEST.PERFORMANCE'),
      tableId: 'tblw0NohBwdqpD66t',
    },
    {
      id: TabId.Roadmap,
      title: t('PRIVATE.USER.TEST.ROADMAP'),
      tableId: 'tblSeuUMWzbB5H31L',
    },
    {
      id: TabId.Backlog,
      title: t('PRIVATE.USER.TEST.BACKLOG'),
      tableId: 'tbljZNynf9auU8snt',
    },
  ];
};

export const filtersInitialState = {
  [SearchParams.ORDER]: '',
  [SearchParams.CLIENT_ID]: '',
};

export const buildTestUrl = (input: {
  [SearchParams.CLIENT_ID]?: string | null;
  [SearchParams.ORDER]?: string | null;
}): string => {
  const filters = {
    [SearchParams.CLIENT_ID]: input[SearchParams.CLIENT_ID] || filtersInitialState[SearchParams.CLIENT_ID],
    [SearchParams.ORDER]: input[SearchParams.ORDER] || filtersInitialState[SearchParams.ORDER],
  };
  const urlParams = getObjectUrlParams(filters);
  return `/${TEST}?${urlParams}`;
};

export interface TestContextProps {
  loading: boolean;
  error: boolean;
  records: AirtableRecord[];
  clients: Client[];

  selectedClientId: string;
  onClientSelected: (clientId: string, fromEditor?: boolean) => void;

  activeTabId: TabId;
  onTabChange: (tab: TabId) => void;

  sort: AirtableSort | null;
  setSort: (sort: AirtableSort) => void;

  page: number;
  pageSize: number;
  total: number;
  setPage: (page: number) => void;
  setPageSize: (size: number) => void;
}

const TestProvider = ({ children }: { children: React.ReactNode }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  const {
    auth: { user },
  } = useTrackedSelector();

  const {
    searchParams: { [SearchParams.CLIENT_ID]: _clientId = filtersInitialState[SearchParams.CLIENT_ID] },
  } = useCustomLocation();

  const [doFetchClients, { data: clientsData, loading: clientsLoading, error: clientsError }] = useLazyQuery<{
    getClients: Clients;
  }>(getClients);
  const [doGetAirtableRecords, { data, loading, error }] = useLazyQuery<{
    getAirtableRecords: AirtableRecord[];
  }>(getAirtableRecords);

  const [records, setRecords] = useState<AirtableRecord[]>([]);

  const [sort, setSort] = useState<AirtableSort | null>(
    searchParams.get('order') === 'asc' || searchParams.get('order') === 'desc'
      ? { field: 'defaultField', direction: searchParams.get('order') as SortDirection }
      : null,
  );

  const [selectedClientId, setSelectedClientId] = useState<string>(_clientId || user?.clientId || '');

  const [activeTabId, setActiveTabId] = useState<TabId>(TabId.AdConcept);

  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(100);
  const [total, setTotal] = useState(0);

  const isPgUserOrCreator = useMemo(() => userHasAnyRole(user, [SecurityRole.PG_USER, SecurityRole.CREATOR]), [user]);

  const clients = useMemo(() => {
    const items = clientsData?.getClients?.items || [];
    if (!selectedClientId && !!items.length) setSelectedClientId(items[0].id);
    return items;
  }, [clientsData?.getClients?.items, selectedClientId]);

  // builds URL search part from the internal state
  const urlParams = useMemo(
    () =>
      getObjectUrlParams({
        [SearchParams.CLIENT_ID]: selectedClientId || _clientId || filtersInitialState[SearchParams.CLIENT_ID],
      }),
    [_clientId, selectedClientId],
  );

  const onClientSelected = useCallback(
    (clientId: string, fromEditor = false) => {
      setSelectedClientId(clientId);
      if (!fromEditor) {
        // setSelectedClientId('');
        navigate(
          buildTestUrl({
            [SearchParams.CLIENT_ID]: clientId,
          }),
        );
      }
    },
    [navigate],
  );

  const fetchAirtableRecords = useCallback(() => {
    const view = clients.find((i) => i.id === selectedClientId);
    if (!view) return;

    const tableId = tabs(t).find((i) => i.id === activeTabId)?.tableId;
    if (!tableId) return;

    doGetAirtableRecords({
      variables: {
        baseId: 'appBJeNdEBVTvrWfj',
        tableIdOrName: tableId,
        view: view.name,
        sort,
        paging: {
          pageSize,
          // offset: (page - 1) * pageSize,
          // cellFormat: 'json',
        } as AirtablePaging,
      },
    });
  }, [activeTabId, clients, doGetAirtableRecords, pageSize, selectedClientId, sort, t]);

  const updateSort = useCallback(
    (newSort: AirtableSort) => {
      setSort(newSort);
      setSearchParams({ order: newSort.direction });
    },
    [setSearchParams],
  );

  const onTabChange = useCallback((tab: TabId) => {
    setRecords([]);
    setActiveTabId(tab);
    setSort(null);
  }, []);

  // useEffect(() => {
  //   if (!loading && !error) {
  //     setRecords(
  //       data?.getAirtableRecords?.map(
  //         (i) =>
  //           ({
  //             id: i.id,
  //             fields: i.fields.filter((j) => j.first !== 'Client'),
  //           } as AirtableRecord),
  //       ) || [],
  //     );
  //     setTotal(data?.getAirtableRecords.length || 0);
  //   }
  // }, [loading, error, data]);

  useEffect(() => {
    if (!loading && !error) {
      const sortedRecords =
        data?.getAirtableRecords?.map((record) => {
          const sortedFields = sortedFieldOrder[activeTabId]
            .map((key) => record.fields.find((field) => field.first === key))
            .filter(Boolean);

          return {
            id: record.id,
            fields: sortedFields as AirtableField[],
          };
        }) || [];

      setRecords(sortedRecords);
      setTotal(sortedRecords.length);
    }
  }, [loading, error, data, activeTabId]);

  // fetch all clients if it's a PG user
  useEffect(() => {
    if (isPgUserOrCreator) {
      doFetchClients({
        variables: {
          sorting: { orderBy: OrderBy.CreatedDateDesc } as Sorting,
          paging: { page: 0, perPage: 100 } as SearchPaging,
        },
      });
    }
  }, [doFetchClients, isPgUserOrCreator]);

  useEffect(() => fetchAirtableRecords(), [fetchAirtableRecords]);

  useEffect(() => {
    navigate(`/${TEST}?${urlParams}`, { replace: true });
  }, [navigate, urlParams]);

  const value = {
    loading: loading || clientsLoading,
    error: !!error || !!clientsError,
    records,
    clients,

    selectedClientId,
    onClientSelected,

    activeTabId,
    onTabChange,

    sort,
    setSort: updateSort,
    page,
    pageSize,
    total,
    setPage,
    setPageSize,
  } as TestContextProps;

  return <TestContext.Provider value={value}>{children}</TestContext.Provider>;
};

export default TestProvider;
